import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { BehaviorSubject, combineLatest, map, Observable, of, skipWhile, switchMap } from 'rxjs';
import { AccountData, UserData } from '../models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../../environments/environment';
import { UserService } from '../entity-data-service/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private accountData: BehaviorSubject<AccountData> = new BehaviorSubject<AccountData>(null);
  private authenticationIsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private authenticationStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Retrieve all relevant verified User data.
   */
  public get verifiedAccountData(): AccountData {
    return this.accountData.getValue();
  }
   
  /**
   * A Verified User has been verified by both Auth0 and the MCC App.
   */
  public get isVerified(): boolean {
    return !!this.accountData.getValue();
  }

  /** 
   * Authenticated is not the same as Verified; 
   * an Authenticated User has been verified only by Auth0, and not necessarily the MCC App. 
   */ 
  public get isAuthenticated(): boolean {
    return this.authenticationStatus.getValue();
  }

  /**
   * This subject is for determining the first time that User Data is pulled successfully.
   */
  public get isAuthenticationLoading(): boolean {
    return this.authenticationIsLoading.getValue();
  }

  constructor(private auth0Service: AuthService, private userService: UserService) {
    console.log('initiating auth service');

    combineLatest([this.auth0Service.isAuthenticated$, this.auth0Service.user$])
      .pipe(
        takeUntilDestroyed(),
        skipWhile(data => {
          const skip = !data.every(x => x);
          // If there is no User Data to pull from Auth0, update the Authentication Loading subject.
          if (skip) this.authenticationIsLoading.next(false);
          return !data.every(x => x);
        }), // is this prevented when logged out?
        switchMap(([isAuthenticated, user]: [boolean, User]) => {
          // Check and store User authentication status immediately
          this.authenticationStatus.next(isAuthenticated);

          const userData$: Observable<UserData> = isAuthenticated ? this.userService.getUserByUsername(user.email) : of(null);
          // Combine the MCC App User Data fetch with the authentication 
          return userData$.pipe(map((userData: UserData) => [isAuthenticated, user, userData]));
        })
      )
      .subscribe({
        next: ([isAuthenticated, auth0User, mccUser]: [boolean, User, UserData]) => {
          /* DEBUG */
          console.log('is authenticated?', isAuthenticated);
          console.log('auth0 user info: ', auth0User);
          console.log('mcc user info: ', mccUser);
          
          // If authenticated by Auth0, but there is no matching User Data, log out.
          if (isAuthenticated && !mccUser) {
            console.error(`MCC App User information not found for '${auth0User.email}'; Logging out...`);
            this.logout();
          } else this.accountData.next({ auth0User, mccUser, isAuthenticated });

          this.authenticationIsLoading.next(false);
        },
        error: e => {
          // If any errors occur during this process, log out.
          console.error(e);
          this.logout();
        }
      });
  }

  public login(): void {
    if (!this.isAuthenticated)
      this.auth0Service.loginWithRedirect({
        authorizationParams: {
          redirect_uri: environment.redirectUrl,
        },
      });
  }

  public logout(): void {
    if (this.isAuthenticated)
      this.auth0Service.logout({
        logoutParams: {
          returnTo: environment.redirectUrl,
        },
      });
  }
}
