import {
  APP_INITIALIZER,
  EnvironmentProviders,
  NgModule,
  Provider,
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseComponent } from './base/base.component';
import { NavbarComponent } from './navbar/navbar.component';
import {
  AuthClientConfig,
  AuthHttpInterceptor,
  authHttpInterceptorFn,
  AuthModule,
} from '@auth0/auth0-angular';
import { withInterceptors } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { environment } from '../environments/environment';
import { HttpModule } from './http.module';
import { MatMenuModule } from '@angular/material/menu'
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';

export function appInit(auth0Config: AuthClientConfig) {
  console.log(environment.enableAuth);

  if (environment.enableAuth)
    return () =>
      auth0Config.set({
        clientId: environment.clientId,
        domain: environment.authority,
        authorizationParams: {
          redirect_uri: environment.redirectUrl,
          audience: environment.authorityId,
        },
        httpInterceptor: {
          /* Developer Note: see the following link for configuring the httpInterceptor, if a public route is needed in the future: */
          /* https://github.com/auth0/auth0-angular/blob/main/EXAMPLES.md#configure-authhttpinterceptor-to-attach-access-tokens */
          /* Otherwise, this interceptor will ensure that all calls to the designated API are protected by Auth0. */
          allowedList: [
            {
              uri: `${environment.apiUrl}/*`,
              tokenOptions: {
                authorizationParams: {
                  audience: environment.authorityId,
                },
              },
            },
          ],
        },
      });
  else return null;
}

const getAuthProviders = (): Provider | EnvironmentProviders => {
  const providers = [
    AuthHttpInterceptor,
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
  ];
  return [...providers];
};

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent, BaseComponent, NavbarComponent, HomeComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressBarModule,
    MatSidenavModule,
    AuthModule.forRoot(),
    HttpModule.forRoot({ environment }),
  ],
  providers: [
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AuthClientConfig],
    },
    getAuthProviders(),
    CommonModule,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
