<div class="zendi-connect">
  <header class="connect-header">
    <app-navbar class="navigation"></app-navbar>  
  </header>
  <mat-progress-bar *ngIf="isAuthenticationLoading" mode="indeterminate"></mat-progress-bar>
  <div class="main-container">
    <main class="main-body">
      <router-outlet></router-outlet>
    </main>
    <app-footer class="main-footer"></app-footer>
  </div>
</div>