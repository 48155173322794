import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Title } from '@angular/platform-browser';
import { ManageService } from '../entity-data-service/manage.service';
import { take, takeUntil } from 'rxjs';
import { AuthorizationService } from '../authorization/authorization.service';
import { environment } from '../../environments/environment';
import { UserData } from '../models';
import { UserService } from '../entity-data-service/user.service';

@Component({
  selector: 'app-home',
  standalone: false,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent {
  users: UserData[] = [];

  protected get isVerified(): boolean {
    return this.authService.isVerified;
  }

  protected get isAuthenticationLoading(): boolean {
    return this.authService.isAuthenticationLoading;
  }

  constructor(
    protected override titleService: Title,
    protected manageService: ManageService,
    private userService: UserService,
    private authService: AuthorizationService
  ) {
    super(titleService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    console.log(environment.navText, environment.apiUrl);

    this.userService.users
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        console.log('fetching...', { data });
        this.users = data;
      });
  }

  fetchUser() {
    console.log('getUserByUsername');

    // (DEBUG) should fail if not logged in
    this.userService
      .getUserByUsername(this.authService.verifiedAccountData.auth0User.email)
      .pipe(take(1))
      .subscribe((data) => {
        console.log(data);
      });
  }

  fetchUsers() {
    console.log('getUsers');
    // console.log(this.ngUnsubscribe);
    this.userService.getUsers();
  }

  logUserData() {
    console.log(this.authService.verifiedAccountData);
  }
}
